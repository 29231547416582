





























































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfileUpdate } from '@/interfaces';
import { readUserProfile } from '@/store/main/getters';
import { readConstants } from '@/store/main/getters';
import { dispatchUpdateUserProfile } from '@/store/main/actions';

@Component
export default class UserProfileEdit extends Vue {
  public valid = true;
  public fullName: string = '';
  public email: string = '';
  public address: string = '';
  public want_Email: boolean = true;
  public otp_key: string = '';

  public publicKeyRules() {
    return [(v) => /^\d{6}$/.test(v) || '請輸入 6 碼驗證碼']
  }

  public created() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      this.fullName = userProfile.full_name;
      this.email = userProfile.email;
      if (userProfile) {
        this.address = userProfile.address;
      } else {
        this.address = '';
      }
      if (userProfile.want_Email === null) {
        this.want_Email = true;
      } else {
        this.want_Email = userProfile.want_Email;
      }
    }

    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public reset() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      this.fullName = userProfile.full_name;
      this.email = userProfile.email;
      this.address = userProfile.address;
      if (userProfile.want_Email === null) {
        this.want_Email = true;
      } else {
        this.want_Email = userProfile.want_Email;
      }
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if ((this.$refs.form as any).validate()) {
      const updatedProfile: IUserProfileUpdate = {};
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      if (this.address) {
        updatedProfile.address = this.address;
      }
      if (this.otp_key) {
        updatedProfile.otp_key = this.otp_key;
      }
      updatedProfile.want_Email = this.want_Email;
      await dispatchUpdateUserProfile(this.$store, updatedProfile);
      this.$router.push('/account');
    }
  }

  public addressRules() {
    return [(v) => (v.startsWith('T') || v.startsWith('0x')) || '地址應以 T 或 0x 開頭',]
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}
